export default {};

export const CTA_COPY_DETAILPAGE_INBOX = {
    /** The id of the flag in optimiziely */
    flagId: 'cta-copy-detailpage-inbox',

    /** The id of the tracking event for this experiment */
    eventKey: 'clicks_on_cta_on_detail_page',

    /** Variants to show on the accept offer button */
    ctaVariants: {
        variant_a: 'Acceptera erbjudandet',
        variant_b: 'Välj erbjudandet och gå vidare',
        variant_c: 'Gå vidare med ansökan',
    },

    /** Variants to show on the tittle of the confirmation page */
    headingVariants: {
        variant_a: 'Du har valt ett erbjudande!',
        variant_b: 'Du har valt erbjudande!',
        variant_c: 'Du har gått vidare med ett erbjudande!',
    },

    /** What variant to show if we cannot connect to Optimizely servers or the test does not work. */
    fallback: 'variant_a',
};
