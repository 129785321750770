import { externalRoutes } from '@frontend/shared/utils';
import { ICampaign } from 'APP/interfaces/helpers';

export const CampaignHelpers = {
    /**
     * Get campaign status as a string
     * @param {object} campaign
     * @returns {'DATA_MISSING' | 'DATA_EXISTS'}
     */
    getCampaignAccountDataStatus(campaign: ICampaign): string {
        if (
            !campaign ||
            !campaign.application_data ||
            !campaign.application_data.clearing_number ||
            !campaign.application_data.account_number
        ) {
            return 'DATA_MISSING';
        }

        return 'DATA_EXISTS';
    },
};

/**
 * Add a new campaign here:
 * 1. Add a new campaign id, this is the id that is used in the backend
 * 2. Add the new terms pdf in GCP bucket by creating a PR in https://github.schibsted.io/lendo-se/cdn.
 * 3. Update the relevant function to return the new pdf
 * 4. If campaign is ULA_KREDDY, add the last date for the campaign.
 */

const campaignTestId = (campaigns: ICampaign[]): string | boolean => {
    return campaigns?.length > 0
        ? campaigns[0].id
        : typeof window !== 'undefined' &&
              (window.location.hostname.includes('vercel') || window.location.hostname.includes('localhost')) &&
              'c27fdd6b-e64a-409b-937e-a10d7039f0d8';
};

export const Campaigns = {
    ULA_KREDDY: 'ac7bbb06-cb59-43e1-9d4b-999a700b15a7',
    ULA_DEFAULT: '703b8424-5ea5-497b-a3ad-742449b923bf',
    ULA_MOBILE_APP: '45851feb-8343-46cd-b1c1-16cf4d2035e6',
    LUNAR: '8644049c-0ce3-4af2-a7e0-6336b6f4562b',
    ULA_TEST: 'c27fdd6b-e64a-409b-937e-a10d7039f0d8',
};

export const ULA_KREDDY_LAST_DATE = '9 maj 2025';

export function getCampaignTermsPdf(): string {
    return `${externalRoutes.cdn}/pdf/Lendo_Kampanjvillkor_ULA_ADR_April_2025.pdf`;
}

export function getLunarCampaignTermsPdf(): string {
    return `${externalRoutes.cdn}/pdf/lunar/Lendo_Kampanjvillkor_Lunar.pdf`;
}

export function getMobileAppCampaignTermsPdf(): string {
    return `${externalRoutes.cdn}/pdf/ULA_villkor_mobileapp_2023.pdf`;
}
export function getKreddyCampaignTermsPdf(): string {
    return `${externalRoutes.cdn}/pdf/Lendo_Kampanjvillkor_ULA_Q2_2025.pdf`;
}
export function doesCampaignIdExist(
    campaigns: ICampaign[],
    applicationCreatedOrigin: string | null
): { ULA_KREDDY: boolean; ULA_DEFAULT: boolean; ULA_MOBILE_APP: boolean; LUNAR: boolean } {
    const campaignResult = {
        ULA_KREDDY: false,
        ULA_DEFAULT: false,
        ULA_MOBILE_APP: false,
        LUNAR: false,
    };

    // All possible applicationCreatedOrigin values are:
    // 'lendose-unknown','lendoinbox','compricer','bytbil','kreddy','lendoapp','lendoadmin','blocket','anmarkning','letalan','lendose'

    if (!campaigns || !applicationCreatedOrigin) return campaignResult;
    const campaignTestIdValue = campaignTestId(campaigns);

    campaigns.forEach(campaign => {
        const campaignId = campaign.id === campaignTestIdValue ? campaign.id : campaignTestIdValue;

        switch (campaignId) {
            case Campaigns.ULA_DEFAULT:
                if (
                    ['lendose-unknown', 'lendose', 'lendoadmin', 'erbjudande-privatlan'].includes(
                        applicationCreatedOrigin
                    )
                ) {
                    campaignResult.ULA_DEFAULT = true;
                }
                if (applicationCreatedOrigin === 'kreddy') {
                    campaignResult.ULA_KREDDY = true;
                }
                break;
            case Campaigns.ULA_KREDDY:
                if (['lendose-unknown', 'lendose', 'erbjudande-privatlan'].includes(applicationCreatedOrigin)) {
                    campaignResult.ULA_DEFAULT = true;
                }
                if (applicationCreatedOrigin === 'kreddy') {
                    campaignResult.ULA_KREDDY = true;
                }
                break;
            case Campaigns.ULA_TEST:
                if (['lendose-unknown', 'lendose'].includes(applicationCreatedOrigin)) {
                    campaignResult.ULA_DEFAULT = true;
                }
                if (applicationCreatedOrigin === 'kreddy') {
                    campaignResult.ULA_KREDDY = true;
                }

                break;
            case Campaigns.ULA_MOBILE_APP:
                if (applicationCreatedOrigin === 'lendoapp') {
                    campaignResult.ULA_MOBILE_APP = true;
                }
                break;
            case Campaigns.LUNAR:
                campaignResult.LUNAR = true;
                break;
        }
    });

    return campaignResult;
}
