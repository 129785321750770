import { OptimizelyDecision } from '@optimizely/react-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAbTestSliceInitialState } from 'APP/interfaces/general.interfaces';

const initialState: IAbTestSliceInitialState = {
    smartSorting: {
        variationKey: '',
        enabled: false,
    },
    ctaCopyDetailPageInbox: {
        variationKey: '',
        enabled: false,
    },
};

const abTestsSlice = createSlice({
    name: 'abTestsSlice',
    initialState,
    reducers: {
        updateAbTest: (state, action: PayloadAction<{ name: string; value: OptimizelyDecision }>) => {
            const abTestName = action.payload.name as keyof IAbTestSliceInitialState;

            state[abTestName].variationKey = action.payload.value.variationKey;
            state[abTestName].enabled = action.payload.value.enabled;
        },
    },
});

export const { updateAbTest } = abTestsSlice.actions;
export default abTestsSlice.reducer;
