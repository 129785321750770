import { CTA_COPY_DETAILPAGE_INBOX } from '@frontend/mortgage/featureFlags';
import { Card, Stack, Typography } from '@lendoab/aphrodite';
import { useDecision } from '@optimizely/react-sdk';
import NextSteps from 'APP/components/Acceptances/NextSteps';
import { Divider } from 'APP/components/Divider';
import ProductLogo from 'APP/components/ProductLogo/ProductLogo';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

export default function SveaOfferReceipt(props) {
    const { offer } = props;

    // Optimizely AB testing
    const { flagId, headingVariants, fallback } = CTA_COPY_DETAILPAGE_INBOX;
    const { ctaCopyDetailPageInbox } = useSelector(reduxState => reduxState.abTestsSlice);
    const [ctaCopyDetailPageInboxDecision] = useDecision(flagId);
    const variationKey = ctaCopyDetailPageInbox.variationKey || ctaCopyDetailPageInboxDecision.variationKey || fallback;
    const headingCopy = headingVariants[variationKey];

    return (
        <Card borderRadius="small" paddingY={['xl', '2xl']} paddingX="none">
            <Stack fluid space="xs" paddingX={['xl', '2xl']}>
                {offer?.product && <ProductLogo product={offer.product} justifyContent="center" />}
                <Typography.Title
                    color="gray-10"
                    level={3}
                    fontWeight="bold"
                    align="center"
                    style={{ marginBottom: 0 }}
                >
                    {headingCopy}
                </Typography.Title>
            </Stack>

            <Divider marginTop={['medium', '2xl']} marginBottom={['medium', '2xl']} />

            <Stack fluid space={['medium', '2xl']} paddingX={['xl', '2xl']}>
                <NextSteps
                    steps={[
                        'En handläggare från Lendo ringer dig för att säkerställa dina uppgifter.',
                        'Sedan ringer Svea Bank dig så snart som möjligt.',
                        'Du kommer att få all information om vad som händer nu och vilka kompletteringar som behövs för att färdigställa din ansökan.',
                    ]}
                />

                <Typography.Body>
                    Har du frågor angående din ansökan? Kontakta Lendos kundtjänst på
                    <Typography.Link marginLeft="base" style={{ whiteSpace: 'nowrap' }} href="tel:0771 13 13 10">
                        0771 13 13 10
                    </Typography.Link>
                    .
                </Typography.Body>
            </Stack>
        </Card>
    );
}

SveaOfferReceipt.propTypes = {
    offer: PropTypes.object.isRequired,
};
