import { createSlice } from '@reduxjs/toolkit';
import { ApiStatus } from 'APP/constants/ApiStatusConstants';
import { BankIdConstants, BankIdStatus } from 'APP/constants/BankIdConstants';

import { getOfferAction, getPollBankIdAction, postOfferAction, postOfferWithBankIdAction } from './offerSliceActions';
import { offerSliceInitialState } from './offerSliceInitialState';

const initialState = offerSliceInitialState;

const offerSlice = createSlice({
    name: 'offerPage',
    initialState,
    extraReducers: builder => {
        builder.addCase(getOfferAction.fulfilled, (state, action) => {
            state.activeOffer = action.payload.offer;
            state.application = action.payload.application;
            state.apiStatus.getOffer = ApiStatus.COMPLETED;
        });
        builder.addCase(getOfferAction.pending, state => {
            state.apiStatus.getOffer = ApiStatus.STARTED;
        });
        builder.addCase(getOfferAction.rejected, state => {
            state.apiStatus.getOffer = ApiStatus.FAILED;
        });
        builder.addCase(postOfferAction.fulfilled, state => {
            state.apiStatus.postOffer = ApiStatus.COMPLETED;
        });
        builder.addCase(postOfferAction.pending, state => {
            state.apiStatus.postOffer = ApiStatus.STARTED;
        });
        builder.addCase(postOfferAction.rejected, state => {
            state.apiStatus.postOffer = ApiStatus.FAILED;
        });
        builder.addCase(postOfferWithBankIdAction.fulfilled, (state, action) => {
            state.bankId.apiStatus.getReference = ApiStatus.COMPLETED;
            state.bankId.status = BankIdStatus.PENDING_AUTH;
            state.bankId.autostartToken = action.payload.autostarttoken;
            state.bankId.reference = action.payload.reference;
        });
        builder.addCase(postOfferWithBankIdAction.pending, state => {
            state.bankId.apiStatus.getReference = ApiStatus.STARTED;
        });
        builder.addCase(postOfferWithBankIdAction.rejected, state => {
            state.bankId.apiStatus.getReference = ApiStatus.FAILED;
            state.bankId.status = BankIdStatus.REJECTED;
        });
        builder.addCase(getPollBankIdAction.fulfilled, (state, action) => {
            state.bankId.apiStatus.poll = ApiStatus.COMPLETED;
            if (action.payload?.data?.progress === 'COMPLETE') {
                state.bankId.status = BankIdStatus.COMPLETED;
                state.bankId.progressStatus = BankIdConstants.PROGRESS.COMPLETE;
            } else if (action.payload?.data?.progress === 'USER_SIGN') {
                state.bankId.progressStatus = BankIdConstants.PROGRESS.USER_SIGN;
                state.bankId.status = BankIdStatus.PENDING_POLL;
                state.bankId.pollCount = state.bankId.pollCount + 1;
            } else if (action.payload?.data?.progress === 'OUTSTANDING_TRANSACTION') {
                state.bankId.progressStatus = BankIdConstants.PROGRESS.OUTSTANDING_TRANSACTION;
                state.bankId.status = BankIdStatus.PENDING_POLL;
                state.bankId.pollCount = state.bankId.pollCount + 1;
            } else {
                state.bankId.status = BankIdStatus.IDLE;
                state.bankId.progressStatus = BankIdConstants.PROGRESS.IDLE;
            }
        });
        builder.addCase(getPollBankIdAction.pending, state => {
            state.bankId.apiStatus.poll = ApiStatus.STARTED;
        });
        builder.addCase(getPollBankIdAction.rejected, state => {
            state.bankId.apiStatus.poll = ApiStatus.FAILED;
            state.bankId.progressStatus = BankIdConstants.PROGRESS.IDLE;
            state.bankId.status = BankIdStatus.REJECTED;
        });
    },
    reducers: {
        updateStep: (state, action) => {
            state.step = action.payload;
        },
        updateIsNotPEP: (state, action) => {
            state.isNotPEP = action.payload;
        },

        updateActiveOffer: (state, action) => {
            state.activeOffer = action.payload;
        },
        updateBizActiveOffer: (state, action) => {
            state.bizActiveOffers = action.payload;
        },
        updateBizExpiredOffer: (state, action) => {
            state.bizExpiredOffers = action.payload;
        },
        updateProductRequirements: (state, action) => {
            state.productRequirements[action.payload.name] = action.payload.value;
        },
        resetSignOfferBankIdStatus: () => initialState,
        resetOfferSlice: () => initialState,
        setUpsells: (state, action) => {
            switch (action.payload.type) {
                case 'loan_insurance':
                    state.upsells.loanInsurance.id = action.payload.id;
                    state.upsells.loanInsurance.active = action.payload.status === 'active' ? true : false;
                    state.upsells.loanInsurance.userAccepted = action.payload.status === 'active' ? true : false;
                    break;
                case 'safety_insurance':
                    state.upsells.safetyInsurance.id = action.payload.id;
                    state.upsells.safetyInsurance.active = action.payload.status === 'active' ? true : false;
                    state.upsells.safetyInsurance.userAccepted = false;
                    break;

                default:
                    break;
            }
        },
        updateUpsells: (state, action) => {
            switch (action.payload.type) {
                case 'loan_insurance':
                    state.upsells.loanInsurance.id = action.payload.id;
                    state.upsells.loanInsurance.userAccepted = !state.upsells.loanInsurance.userAccepted;
                    break;
                case 'safety_insurance':
                    state.upsells.safetyInsurance.id = action.payload.id;
                    state.upsells.safetyInsurance.userAccepted = !state.upsells.safetyInsurance.userAccepted;
                    break;

                default:
                    break;
            }
        },
        updateCompanyInfo: (state, action) => {
            state.application.company_info = action.payload.company_info;
        },
        updateApplicationId: (state, action) => {
            state.application.id = action.payload;
        },
        updateComplements: (state, action) => {
            state.fileUploadComplements = action.payload;
        },
    },
});
export const {
    updateStep,
    resetOfferSlice,
    updateUpsells,
    updateCompanyInfo,
    updateApplicationId,
    updateProductRequirements,
    updateIsNotPEP,
    setUpsells,
    updateActiveOffer,
    resetSignOfferBankIdStatus,
    updateBizActiveOffer,
    updateBizExpiredOffer,
    updateComplements,
} = offerSlice.actions;
export default offerSlice.reducer;
