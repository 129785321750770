import { externalRoutes } from '@frontend/shared/utils';
import { Box, ContentBlock } from '@lendoab/aphrodite';
import { Analytics } from 'APP/Analytics';
import { useAuth } from 'APP/Authentication';
import { fetchActiveAllApplicationsAction } from 'APP/feature/applications/applicationsSliceActions';
import { useAppDispatch } from 'APP/feature/configStore';
import { resetReduxStates } from 'APP/feature/general/generalSlice';
import { classNames } from 'APP/helpers/css';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { HamburgerToggle } from '../MyPagesUI/HamburgerToggle/HamburgerToggle';
import { LogOutButtonDesktop } from '../MyPagesUI/LogOutButtonDesktop/LogOutButtonDesktop';
import { getScrollBarWidth } from './helpers';
import styles from './Navigation.module.scss';
import { Overlay } from './Overlay';
import { SubNavDesktop } from './SubNavDesktop';
import { SubNavMobile } from './SubNavMobile';

export function Navigation(): ReactNode {
    const { events } = useRouter();
    const [open, setOpen] = useState(false);
    const navClassNames = classNames(styles.nav, open && styles.open);
    const { state } = useAuth();
    const dispatch = useAppDispatch();
    const isAuthenticated = state.status === 'authenticated';

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchActiveAllApplicationsAction());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {
        const handleRouteChange = (): void => {
            setOpen(false);
        };

        events.on('routeChangeStart', handleRouteChange);

        return () => {
            events.off('routeChangeStart', handleRouteChange);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const metaTag = document.createElement('meta');
        metaTag.setAttribute('name', 'theme-color');
        metaTag.setAttribute('content', '#2AC768');
        document.head.appendChild(metaTag);
        return () => {
            document.head.querySelector('meta[name="theme-color"]')?.remove();
        };
    }, []);

    return (
        <Fragment>
            <Box
                fluid
                component="nav"
                display={['flex', 'flex', 'block']}
                justifyContent="center"
                alignItems="center"
                className={navClassNames}
                flexDirection="column"
            >
                <Box
                    backgroundColor="green-50"
                    fluid
                    style={{
                        paddingRight: open ? getScrollBarWidth() : undefined,
                        zIndex: 5,
                    }}
                >
                    <ContentBlock paddingX="none">
                        <TopNavBarMobile open={open} setOpen={setOpen} />
                        <TopNavBarDesktop />
                    </ContentBlock>
                </Box>
                <SubNav open={open} setOpen={setOpen} />
            </Box>
            <Overlay open={open} onClick={() => setOpen(false)} />
        </Fragment>
    );
}

export interface NavBarProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

function SubNav({ open, setOpen }: NavBarProps): React.ReactNode {
    const [expandedItem, setExpandedItem] = useState<string | null>(null);

    function handleChevronClick(item: string, isOpen: boolean): void {
        if (isOpen) {
            setExpandedItem(item);
        } else {
            setExpandedItem(null);
        }
    }

    useEffect(() => {
        if (!open) {
            setExpandedItem(null);
        }
    }, [open]);

    return (
        <>
            <SubNavMobile
                onChevronClick={handleChevronClick}
                expandedItem={expandedItem}
                open={open}
                setOpen={setOpen}
            />
            <SubNavDesktop
                onChevronClick={handleChevronClick}
                expandedItem={expandedItem}
                open={open}
                setOpen={setOpen}
            />
        </>
    );
}

function TopNavBarMobile(props: NavBarProps): ReactNode {
    const { setOpen, open } = props;
    function onHamburgerClicked(): void {
        Analytics.pushUIInteraction({
            type: !open ? 'open' : 'close',
            category: 'sidemenu',
            title: !open ? 'Menu' : 'Close',
        });

        setOpen(!open);
    }

    return (
        <Box
            fluid
            display={['flex', 'flex', 'none']}
            justifyContent="space-between"
            alignItems="center"
            padding="small"
            paddingLeft="large"
        >
            <NavLogo />

            <Box display="flex" alignItems="center">
                <HamburgerToggle onClick={onHamburgerClicked} open={open} marginLeft={['medium', 'xl']} />
            </Box>
        </Box>
    );
}

function TopNavBarDesktop(): ReactNode {
    const { unAuthenticate } = useAuth();
    const dispatch = useDispatch();

    return (
        <Box
            fluid
            display={['none', 'none', 'flex']}
            justifyContent="space-between"
            alignItems="center"
            padding="medium"
        >
            <NavLogo />

            <LogOutButtonDesktop
                onClick={(): void => {
                    unAuthenticate();
                    dispatch(resetReduxStates());
                }}
            />
        </Box>
    );
}

function NavLogo(): ReactNode {
    const url = '/';
    function onLogoClicked(): void {
        Analytics.pushUIInteraction({
            type: 'click',
            category: 'navigation',
            title: 'logo',
        });
    }
    return (
        <Link href={url}>
            <Box onClick={onLogoClicked}>
                <img height={32} src={`${externalRoutes.cdn}/img/logos/logo_white.svg`} alt="lendo logo" />
            </Box>
        </Link>
    );
}
