import { Box, Typography } from '@lendoab/aphrodite';
import { useAuth } from 'APP/Authentication';
import { useAppSelector } from 'APP/feature/configStore';
import { classNames } from 'APP/helpers/css';
import { LoanPurposeLabels, MortgageTypeLabels } from 'APP/interfaces/applications.interfaces';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Link from 'next/link';
import React, { ReactNode, useEffect, useRef } from 'react';

import { LogOutButtonMobile } from '../MyPagesUI/LogOutButtonMobile/LogOutButtonMobile';
import { FirstLevelNavigationLink } from './FirstLevelNavigationLink';
import { businessLoanLinkClick, consumerLoanLinkClick, mortgageLinkClick } from './helpers';
import { LinkProps } from './interfaces';
import styles from './SubNavMobile.module.scss';

interface Props extends LinkProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export function SubNavMobile(props: Props): ReactNode {
    const { onChevronClick, open, setOpen, expandedItem } = props;

    const subNavMobileClassNames = classNames(styles.subNavMobile, open && styles.open);
    const subNavMobileContentClassNames = classNames(styles.content, open && styles.open);
    const navRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open && navRef?.current) {
            disableBodyScroll(navRef.current, {
                reserveScrollBarGap: true,
            });
        }

        return () => {
            clearAllBodyScrollLocks();
        };
    }, [open]);

    return (
        <Box
            display={['block', 'block', 'none']}
            className={subNavMobileClassNames}
            data-testid="second-level-navigation-mobile"
        >
            <Box fluid className={subNavMobileContentClassNames} backgroundColor="white">
                <Box className={styles.innerContent} ref={navRef}>
                    <Box display="flex" flexDirection="column">
                        <ConsumerLoanLinks expandedItem={expandedItem} onChevronClick={onChevronClick} />
                        <BusinessLoanLinks expandedItem={expandedItem} onChevronClick={onChevronClick} />
                        <MortgageLinks expandedItem={expandedItem} onChevronClick={onChevronClick} />
                    </Box>
                </Box>
                <LogOutButton onClick={(): void => setOpen(false)} />
            </Box>
        </Box>
    );
}

function ConsumerLoanLinks({ expandedItem, onChevronClick }: LinkProps): ReactNode {
    const applications = useAppSelector(
        state => state.applicationsSlice.allActiveApplications
    ).consumerLoanApplications;

    // TODO: Temporary implementation until the product page in LENSE-6077 is done.
    if (!applications.length) {
        return null;
    }

    return (
        <SubNavLink
            text="Privatlån"
            onChevronClick={(isOpen: boolean) => onChevronClick('consumer-loan', isOpen)}
            isExpanded={expandedItem === 'consumer-loan'}
        >
            {applications.map(application => (
                <SubNavLinkItem
                    key={application.id}
                    href={`/applications/${application.id}`}
                    text="Privatlån"
                    subText={LoanPurposeLabels[application.loan_purpose]}
                    onClick={() => consumerLoanLinkClick(application)}
                />
            ))}
        </SubNavLink>
    );
}

function BusinessLoanLinks({ expandedItem, onChevronClick }: LinkProps): ReactNode {
    const applications = useAppSelector(
        state => state.applicationsSlice.allActiveApplications
    ).businessLoanApplications;

    // TODO: Temporary implementation until the product page in LENSE-6079 is done.
    if (!applications.length) {
        return null;
    }

    return (
        <SubNavLink
            text="Företagslån"
            onChevronClick={(isOpen: boolean) => onChevronClick('business-loan', isOpen)}
            isExpanded={expandedItem === 'business-loan'}
        >
            {applications.map(application => (
                <SubNavLinkItem
                    key={application.id}
                    href={`/applications/${application.id}`}
                    text="Företagslån"
                    subText={application.company_name}
                    onClick={() => businessLoanLinkClick(application)}
                />
            ))}
        </SubNavLink>
    );
}

function MortgageLinks({ expandedItem, onChevronClick }: LinkProps): ReactNode {
    const applications = useAppSelector(state => state.applicationsSlice.allActiveApplications).mortgageApplications;
    const unreadMessages = applications.reduce((acc, application) => acc + application.unread_messages, 0);

    // TODO: Temporary implementation until the product page in LENSE-6078 is done.
    if (!applications.length) {
        return null;
    }

    return (
        <SubNavLink
            text="Bolån"
            onChevronClick={(isOpen: boolean) => onChevronClick('mortgage', isOpen)}
            isExpanded={expandedItem === 'mortgage'}
            unreadMessages={unreadMessages}
        >
            {applications.map(application => {
                const messagesCountText =
                    application.unread_messages > 0
                        ? `(${application.unread_messages} ${application.unread_messages > 1 ? 'meddelanden' : 'meddelande'})`
                        : '';

                return (
                    <SubNavLinkItem
                        key={application.id}
                        href={`/applications/mortgage/${application.id}`}
                        text={`${MortgageTypeLabels[application.type]} ${messagesCountText}`}
                        subText={application.address}
                        onClick={() => mortgageLinkClick(application)}
                    />
                );
            })}
        </SubNavLink>
    );
}

interface SubNavLinkProps extends React.PropsWithChildren {
    text: string;
    isExpanded: boolean;
    onChevronClick: (isOpen: boolean) => void;
    unreadMessages?: number;
}

function SubNavLink({ text, isExpanded, onChevronClick, unreadMessages, children }: SubNavLinkProps): ReactNode {
    const sublevelRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <FirstLevelNavigationLink
                text={text}
                onChevronClick={(isOpen: boolean) => onChevronClick(isOpen)}
                isOpen={isExpanded}
                unreadMessages={unreadMessages}
            />
            <Box
                className={classNames(styles.mobileSecondLevel, isExpanded && styles.mobileSecondLevelExpanded)}
                ref={sublevelRef}
                style={{ maxHeight: isExpanded ? sublevelRef.current?.scrollHeight : 0 }}
            >
                {children}
            </Box>
        </>
    );
}

interface SubNavLinkItemProps {
    href: string;
    text: string;
    subText: string;
    onClick: () => void;
}

function SubNavLinkItem({ href, text, subText, onClick }: SubNavLinkItemProps): ReactNode {
    return (
        <Link href={href} onClick={onClick} data-testid="second-level-navigation-item">
            <Box backgroundColor="blue-95" paddingLeft="3xl" paddingY="medium">
                <Typography.Body color="gray-10" weight="bold">
                    {text}
                </Typography.Body>
                <Typography.Body color="gray-10">{subText}</Typography.Body>
            </Box>
        </Link>
    );
}

function LogOutButton(props: { onClick: () => void }): ReactNode {
    const { onClick } = props;
    const { unAuthenticate } = useAuth();

    function handleClick(): void {
        unAuthenticate();
        onClick();
    }

    return (
        <Box margin="xl" paddingTop="xl" marginTop="auto">
            <LogOutButtonMobile onClick={handleClick} />
        </Box>
    );
}
