/**
 * {@link https://confluence.schibsted.io/display/LAT/Element+Interactions}
 */

import { FieldName, FieldType, INTERACTIONS, PRIVATE_APPLICATION_FORM } from '@frontend/shared/constants';

import {
    ApplicationInfo,
    AppStore,
    BackButtonClickedTrackingProps,
    ConsentInfo,
    ContentInfo,
    DataLayerPayload,
    ElementInfo,
    ElementInfoPayload,
    EventInfo,
    FormFieldInfo,
    FormInfo,
    IBankIdStatus,
    InteractionInfo,
    MessageInfo,
    OfferInfo,
    OptimizelyInfo,
    PageViewPayload,
    UserInfo,
    WebVitalsInfo,
} from './analytics.interfaces';

export const events = {
    APPLICATION_REJECTED: 'applicationRejected',
    APPLICATION_SUBMITTED: 'applicationSubmitted',
    AUTOGIRO_ACCEPT: 'autogiroAccept',
    CONSENT_INTERACTION: 'consentInteraction',
    CONTENT_CLICK: 'contentClick',
    DYNAMIC_PAGE_VIEW: 'dynamicPageview',
    ELEMENT_INTERACTION: 'elementInteraction',
    EXPLAINER_CLICKED: 'explainerClicked',
    EXPLAINER: 'explainerClicked',
    FILE_UPLOAD_COMPLETED: 'fileUploadCompleted',
    FILE_UPLOAD_IMPRESSION: 'fileUploadImpression',
    FILE_UPLOAD_SENT: 'fileUploadSent',
    FILE_UPLOAD: 'fileUpload',
    FLOW_POST_ACCEPT_COMPLETED: 'postAcceptFlowCompleted',
    FLOW_POST_ACCEPT_STARTED: 'postAcceptFlowStarted',
    FLOW_STEP_IMPRESSION: 'flowStepImpression',
    FORM_FIELD_ERROR: 'formFieldError',
    FORM_FIELD_INTERACTION: 'formFieldInteraction',
    FORM_STEP_IMPRESSION: 'formStepImpression',
    FORM_STEP_SUBMIT: 'formStepSubmit',
    INSURANCE_UPSELL: 'insuranceUpsell',
    LOAN_CALCULATOR_RESULT: 'loanCalculatorResult',
    LOGIN: 'login',
    MODAL_CONFIRMED: 'modalConfirmed',
    MODAL_DISMISSED: 'modalDismissed',
    MODAL_SHOWN: 'modalShown',
    OFFER_ACCEPTED: 'offerAccepted',
    OFFER_CLICK: 'offerClick',
    OFFER_DETAILS_EXPANDED: 'offerDetailsExpanded',
    OFFER_LIST_IMPRESSION: 'offerListImpression',
    OPTIMIZELY_DECISION: 'optimizelyDecision',
    REVISION_REJECTED: 'revisionRejected',
    REVISION_SUBMITTED: 'revisionSubmitted',
    SIGN_AT_BANKED_CLICKED: 'signAtBankedClicked',
    UI_INTERACTION: 'UIInteraction',
    USER_AGREEMENT_SIGNED: 'userAgreementSigned',
    WEB_VITALS: 'webVitals',
};

declare global {
    interface Window {
        dataLayer: unknown[];
    }
}

export function pushToDL(payload: DataLayerPayload): void {
    if (!window.dataLayer) {
        window.dataLayer = [];
    } else if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = [];
    }
    window.dataLayer.push(payload);
}

export function pushUIInteraction(interactionInfo: InteractionInfo): void {
    pushToDL({
        event: events.UI_INTERACTION,
        interactionInfo: {
            type: interactionInfo.type,
            category: interactionInfo.category,
            position: interactionInfo.position,
            title: interactionInfo.title,
        },
    });
}

export function pushOptimizelyDecision(optimizelyInfo: OptimizelyInfo): void {
    pushToDL({
        event: 'optimizelyDecision',
        optimizelyInfo: {
            userId: optimizelyInfo.userId,
            enabled: optimizelyInfo.enabled,
            flagKey: optimizelyInfo.flagKey,
            variationKey: optimizelyInfo.variationKey,
            type: optimizelyInfo.decisionEventDispatched ?? '',
        },
    });
}

/**
 * {@link https://confluence.schibsted.io/display/LAT/Form+tracking}
 */
export function pushFormStepImpression(formInfo: FormInfo): void {
    pushToDL({ event: events.FORM_STEP_IMPRESSION, formInfo });
}

export function pushAppStoreCTA(appstoreCTAInfo: AppStore): void {
    pushToDL({
        event: 'appStoreClick',
        eventInfo: {
            store: appstoreCTAInfo.store,
            context: appstoreCTAInfo.context,
        },
    });
}

/**
 * {@link https://confluence.schibsted.io/display/LAT/Form+tracking}
 */
export function pushFormStepSubmit(formInfo: FormInfo, eventInfo: EventInfo): void {
    pushToDL({ event: events.FORM_STEP_SUBMIT, formInfo, eventInfo });
}

/**
 * {@link https://confluence.schibsted.io/display/LAT/Form+tracking}
 */
export function pushFormFieldInteraction(formInfo: FormInfo, formFieldInfo: FormFieldInfo, eventInfo: EventInfo): void {
    pushToDL({
        event: events.FORM_FIELD_INTERACTION,
        formInfo,
        formFieldInfo,
        eventInfo,
    });
}

/**
 * {@link https://confluence.schibsted.io/display/LAT/Form+tracking}
 */
export function pushFormFieldError(formInfo: FormInfo, formFieldInfo: FormFieldInfo, messageInfo: MessageInfo): void {
    pushToDL({
        event: events.FORM_FIELD_ERROR,
        formInfo,
        formFieldInfo,
        messageInfo,
    });
}

export function pushExplainer(explainerName: string): void {
    pushToDL({
        event: events.EXPLAINER,
        eventInfo: {
            explainerName,
        },
    });
}

export function backButtonClickedTracking({ stepName, stepNumber }: BackButtonClickedTrackingProps): void {
    pushFormFieldInteraction(
        {
            name: PRIVATE_APPLICATION_FORM,
            stepName: stepName,
            stepNumber: stepNumber,
            product: 'private',
        },
        {
            type: FieldType.Button,
            value: '',
            name: FieldName.Backwards,
        },
        {
            interaction: INTERACTIONS.PRESS,
        }
    );
}

/**
 * {@link https://confluence.schibsted.io/pages/viewpage.action?spaceKey=LAT&title=Bank+ID}
 */
export function pushBankIdStatus({ event, eventInfo }: IBankIdStatus): void {
    pushToDL({ event, eventInfo });
}

export function pushPageView(payload: PageViewPayload): void {
    const { product, location, title, trackingId, path, msg_medium, msg_action, msg_template } = payload;

    pushToDL({
        event: events.DYNAMIC_PAGE_VIEW,
        pageInfo: {
            product,
            location,
            path,
            title,
            trackingId,
            msg_medium,
            msg_action,
            msg_template,
        },
    });
}

/**
 * {@link https://confluence.schibsted.io/display/LAT/Loan+Calculator+Result}
 */
export function pushLoanCalculatorResult(eventInfo: { value: string }): void {
    pushToDL({ event: events.LOAN_CALCULATOR_RESULT, eventInfo });
}

/**
 * {@link https://confluence.schibsted.io/pages/viewpage.action?pageId=150018390}
 */
export function pushApplicationSubmitted(applicationInfo?: ApplicationInfo, userInfo?: UserInfo): void {
    pushToDL({ event: events.APPLICATION_SUBMITTED, applicationInfo, userInfo });
}

/**
 * {@link https://confluence.schibsted.io/display/LAT/Consent+banner}
 */
export function pushConsentInteraction(consentInfo: ConsentInfo): void {
    pushToDL({ event: events.CONSENT_INTERACTION, consentInfo });
}

/**
 * Documentation not yet available
 * Example implementation:
 * {@link https://github.schibsted.io/lendo-se/lendo4-lendose/blob/40163456297fee6e9144946fb2b6fe71faf324a2/src/web-vitals.js}
 */
export function pushWebVitals(webVitalsInfo: WebVitalsInfo): void {
    pushToDL({
        event: events.WEB_VITALS,
        webVitalsInfo,
    });
}

/**
 * {@link https://confluence.schibsted.io/display/LAT/Editorial+Content}
 */
export function pushContentClick(contentInfo: ContentInfo): void {
    pushToDL({ event: events.CONTENT_CLICK, contentInfo });
}

/**
 * {@link https://schibstedio.atlassian.net/wiki/spaces/LAT/pages/64654340/elementInteraction}
 */
export function pushElementInteraction(elementInfo: ElementInfo): void {
    const { id, group, url, type, context, activeNotification } = elementInfo;

    const elementInfoPayload: ElementInfoPayload = {};

    if (id) {
        elementInfoPayload.id = id;
    }
    if (group) {
        elementInfoPayload.group = group;
    }
    if (url) {
        elementInfoPayload.url = url;
    }

    const eventPayload = {
        event: events.ELEMENT_INTERACTION,
        elementInfo: elementInfoPayload,
        eventInfo: {
            interaction: type,
            context,
            activeNotification,
        },
    };

    pushToDL(eventPayload);
}

/**
 * {@link https://confluence.schibsted.io/pages/viewpage.action?pageId=150018390}
 */
export function pushApplicationRejected(eventInfo?: EventInfo): void {
    pushToDL({ event: events.APPLICATION_REJECTED, eventInfo });
}

// FILE UPLOAD EVENTS

/**
 * User gets exposed for the file upload module.
 * {@link https://schibstedio.atlassian.net/wiki/spaces/LAT/pages/64651930/Inbox+V4#Inbox(V4)-Fileuploads}
 */
export function pushFileUploadImpression(
    applicationInfo?: ApplicationInfo,
    offerInfo?: OfferInfo,
    eventInfo?: EventInfo
): void {
    pushToDL({ event: events.FILE_UPLOAD_IMPRESSION, applicationInfo, offerInfo, eventInfo });
}

/**
 * User has uploaded files and clicks in 'skicka in uppladdade dokument'.
 * {@link https://schibstedio.atlassian.net/wiki/spaces/LAT/pages/64651930/Inbox+V4#Inbox(V4)-Fileuploads}
 */
export function pushFileUploadSent(
    applicationInfo?: ApplicationInfo,
    offerInfo?: OfferInfo,
    eventInfo?: EventInfo
): void {
    pushToDL({ event: events.FILE_UPLOAD_SENT, applicationInfo, offerInfo, eventInfo });
}

/**
 * User has uploaded and submitted files and gets exposed for the page 'dina dokument är inskickade för granskning'.
 * {@link https://schibstedio.atlassian.net/wiki/spaces/LAT/pages/64651930/Inbox+V4#Inbox(V4)-Fileuploads}
 */
export function pushFileUploadCompleted(
    applicationInfo?: ApplicationInfo,
    offerInfo?: OfferInfo,
    eventInfo?: EventInfo
): void {
    pushToDL({ event: events.FILE_UPLOAD_COMPLETED, applicationInfo, offerInfo, eventInfo });
}

/**
 * Tracks the user’s return visits to submit new or re-submitted files whenever the bank either rejects files or requests additional ones, incrementing the instance counter with each visit.
 * Note: The CRO team does not have official documentation as the time of this release.
 */
export function pushPostAcceptFlowStarted(
    applicationInfo?: ApplicationInfo,
    offerInfo?: OfferInfo,
    eventInfo?: EventInfo
): void {
    pushToDL({ event: events.FLOW_POST_ACCEPT_STARTED, applicationInfo, offerInfo, eventInfo });
}

/**
 * Increases the instance counter every time the user completes submitting the requirements.
 * Note: The CRO team does not have official documentation as the time of this release.
 */
export function pushPostAcceptFlowCompleted(
    applicationInfo?: ApplicationInfo,
    offerInfo?: OfferInfo,
    eventInfo?: EventInfo
): void {
    pushToDL({ event: events.FLOW_POST_ACCEPT_COMPLETED, applicationInfo, offerInfo, eventInfo });
}
