import { Box, FontAwesomeIcon, IconsRegular, Typography } from '@lendoab/aphrodite';
import React, { ReactElement } from 'react';

import styles from './LogOutButtonMobile.module.scss';

interface Props {
    onClick: () => void;
}

export function LogOutButtonMobile(props: Props): ReactElement {
    const { onClick } = props;
    return (
        <Box onClick={onClick} component="button" fluid className={styles.logOutButtonMobile}>
            <Typography.Body weight="medium" color="blue-40">
                Logga ut
            </Typography.Body>
            <FontAwesomeIcon icon={IconsRegular.faArrowRightFromBracket} color="blue-40" />
        </Box>
    );
}
