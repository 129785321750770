import { Box, FontAwesomeIcon, IconsRegular, Typography } from '@lendoab/aphrodite';
import React, { ReactElement } from 'react';

import styles from './LogOutButtonDesktop.module.scss';

interface Props {
    onClick: () => void;
}

export function LogOutButtonDesktop(props: Props): ReactElement {
    const { onClick } = props;
    return (
        <Box onClick={onClick} className={styles.logOutButtonDesktop} component="button">
            <Typography.Body weight="medium">Logga ut</Typography.Body>
            <FontAwesomeIcon icon={IconsRegular.faArrowRightFromBracket} />
        </Box>
    );
}
