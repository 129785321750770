import { Box, ContentBlock, FontAwesomeIcon, IconsRegular, Typography } from '@lendoab/aphrodite';
import { useAppSelector } from 'APP/feature/configStore';
import { classNames, useWindowSize } from 'APP/helpers/css';
import { LoanPurposeLabels, MortgageTypeLabels } from 'APP/interfaces/applications.interfaces';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { FirstLevelNavigationLink } from './FirstLevelNavigationLink';
import { businessLoanLinkClick, consumerLoanLinkClick, getScrollBarWidth, mortgageLinkClick } from './helpers';
import { LinkProps } from './interfaces';
import styles from './SubNavDesktop.module.scss';

interface Props extends LinkProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export function SubNavDesktop(props: Props): ReactNode {
    const { onChevronClick, expandedItem, setOpen, open } = props;

    const applications = useAppSelector(state => state.applicationsSlice.allActiveApplications);
    const totalCountApplications = Object.values(applications).reduce((acc, arr) => acc + arr.length, 0);

    const [scrolled, setScrolled] = useState(false);
    const subNavClassNames = classNames(scrolled && styles.scrolled);

    const handleOnChevronClick = (product: string, isOpen: boolean): void => {
        onChevronClick(product, isOpen);
        setOpen(isOpen);
    };

    useEffect(() => {
        const handleScroll = (): void => {
            setScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll, {
            passive: true,
        });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // TODO: Temporary implementation until the start page LENSE-6080 is done and the sub nav will always be visible.
    if (totalCountApplications <= 1) {
        return null;
    }

    return (
        <Box
            backgroundColor="white"
            className={subNavClassNames}
            data-subnav-desktop
            data-testid="second-level-navigation-desktop"
            style={{
                paddingRight: open ? getScrollBarWidth() : undefined,
            }}
        >
            <ContentBlock>
                <Box display={['none', 'none', 'flex']} justifyContent="flex-end" data-testid="first-level-navigation">
                    <ConsumerLoanLinks expandedItem={expandedItem} onChevronClick={handleOnChevronClick} />
                    <BusinessLoanLinks expandedItem={expandedItem} onChevronClick={handleOnChevronClick} />
                    <MortgageLinks expandedItem={expandedItem} onChevronClick={handleOnChevronClick} />
                </Box>
            </ContentBlock>
        </Box>
    );
}

function ConsumerLoanLinks({ expandedItem, onChevronClick }: LinkProps): ReactNode {
    const { query } = useRouter();
    const applicationId = query.id as string;
    const applications = useAppSelector(
        state => state.applicationsSlice.allActiveApplications
    ).consumerLoanApplications;
    const isActive = applications.map(application => application.id).includes(applicationId);

    // TODO: Temporary implementation until the product page in LENSE-6077 is done.
    if (!applications.length) {
        return null;
    }

    return (
        <SubNavLink
            text="Privatlån"
            isActive={isActive}
            onChevronClick={(isOpen: boolean) => onChevronClick('consumer-loan', isOpen)}
            isExpanded={expandedItem === 'consumer-loan'}
        >
            {applications.map(application => (
                <SubNavLinkItem
                    key={`subnav-desktop-${application.id}`}
                    href={`/applications/${application.id}`}
                    text="Privatlån"
                    subText={LoanPurposeLabels[application.loan_purpose]}
                    onClick={() => consumerLoanLinkClick(application)}
                />
            ))}
        </SubNavLink>
    );
}

function BusinessLoanLinks({ expandedItem, onChevronClick }: LinkProps): ReactNode {
    const { query } = useRouter();
    const applicationId = query.id as string;
    const applications = useAppSelector(
        state => state.applicationsSlice.allActiveApplications
    ).businessLoanApplications;
    const isActive = applications.map(application => application.id).includes(applicationId);

    // TODO: Temporary implementation until the product page in LENSE-6079 is done.
    if (!applications.length) {
        return null;
    }

    return (
        <SubNavLink
            text="Företagslån"
            isActive={isActive}
            onChevronClick={(isOpen: boolean) => onChevronClick('business-loan', isOpen)}
            isExpanded={expandedItem === 'business-loan'}
        >
            {applications.map(application => (
                <SubNavLinkItem
                    key={`subnav-desktop-${application.id}`}
                    href={`/applications/${application.id}`}
                    text="Företagslån"
                    subText={application.company_name}
                    onClick={() => businessLoanLinkClick(application)}
                />
            ))}
        </SubNavLink>
    );
}

function MortgageLinks({ expandedItem, onChevronClick }: LinkProps): ReactNode {
    const { query } = useRouter();
    const applicationId = query.id as string;
    const applications = useAppSelector(state => state.applicationsSlice.allActiveApplications).mortgageApplications;
    const isActive = applications.map(application => application.id).includes(applicationId);
    const unreadMessages = applications.reduce((acc, application) => acc + application.unread_messages, 0);

    // TODO: Temporary implementation until the product page in LENSE-6078 is done.
    if (!applications.length) {
        return null;
    }

    return (
        <SubNavLink
            text="Bolån"
            isActive={isActive}
            onChevronClick={(isOpen: boolean) => onChevronClick('mortgage', isOpen)}
            isExpanded={expandedItem === 'mortgage'}
            unreadMessages={unreadMessages}
        >
            {applications.map(application => {
                const messagesCountText =
                    application.unread_messages > 0
                        ? `(${application.unread_messages} ${application.unread_messages > 1 ? 'meddelanden' : 'meddelande'})`
                        : '';

                return (
                    <SubNavLinkItem
                        key={`subnav-desktop-${application.id}`}
                        href={`/applications/mortgage/${application.id}`}
                        text={`${MortgageTypeLabels[application.type]} ${messagesCountText}`}
                        subText={application.address}
                        onClick={() => mortgageLinkClick(application)}
                    />
                );
            })}
        </SubNavLink>
    );
}

interface SubNavLinkProps extends React.PropsWithChildren {
    text: string;
    isExpanded: boolean;
    onChevronClick: (isOpen: boolean) => void;
    isActive: boolean;
    unreadMessages?: number;
}

function SubNavLink({
    text,
    isExpanded,
    onChevronClick,
    isActive,
    unreadMessages,
    children,
}: SubNavLinkProps): ReactNode {
    const subNavLinkClassNames = classNames(styles.subNavDesktop, isExpanded && styles.isExpanded);

    const subNavRef = useRef<HTMLDivElement>(null);
    const subNavMarginLeft = useSubNavDesktopOffset(isExpanded ? subNavRef.current : null);

    return (
        <Box position="relative">
            <FirstLevelNavigationLink
                text={text}
                onChevronClick={(isOpen: boolean) => onChevronClick(isOpen)}
                isOpen={isExpanded}
                isActive={isActive}
                unreadMessages={unreadMessages}
            />
            <Box
                className={subNavLinkClassNames}
                display="flex"
                flexDirection="column"
                ref={subNavRef}
                style={{ left: subNavMarginLeft }}
            >
                {children}
            </Box>
        </Box>
    );
}
interface SubNavLinkItemProps {
    href: string;
    text: string;
    subText: string;
    onClick: () => void;
}

function SubNavLinkItem({ href, text, subText, onClick }: SubNavLinkItemProps): ReactNode {
    return (
        <Link data-testid="second-level-navigation-item" href={href} onClick={onClick} className={styles.link}>
            <Box>
                <Typography.Body weight="bold">{text}</Typography.Body>
                <Typography.Body weight="book">{subText}</Typography.Body>
            </Box>
            <Box display="flex" alignItems="flex-end">
                <FontAwesomeIcon icon={IconsRegular.faArrowRight} color="blue-40" />
            </Box>
        </Link>
    );
}

function useSubNavDesktopOffset(subNav: HTMLDivElement | null): number {
    const [subNavMarginLeft, setSubNavMarginLeft] = useState<number>(0);

    const [windowWidth] = useWindowSize();

    useEffect(() => {
        if (windowWidth && subNav) {
            const MARGIN_RIGHT = 16;
            const subNavBoundingClientRect = subNav.getBoundingClientRect();
            const subNavBoxRightPosition = subNavBoundingClientRect.x + subNavBoundingClientRect.width;

            const marginLeft = subNavMarginLeft + windowWidth - subNavBoxRightPosition - MARGIN_RIGHT;

            if (marginLeft < 0) {
                setSubNavMarginLeft(marginLeft);
            }
        } else {
            setSubNavMarginLeft(0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, subNav]);

    return subNavMarginLeft;
}
