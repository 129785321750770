import { classNames } from 'APP/helpers/css';
import React, { ReactElement } from 'react';

import styles from './Overlay.module.scss';

export function Overlay(props: { open: boolean; onClick: () => void }): ReactElement {
    const { open, ...rest } = props;
    const overlayClassNames = classNames(styles.overlay, open && styles.open);
    return <div data-testid="overlay" className={overlayClassNames} {...rest}></div>;
}
