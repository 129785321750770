import { useLayoutEffect, useState } from 'react';

export function classNames(...classes: (string | false | null | undefined)[]): string {
    return classes.filter(Boolean).join(' ');
}

interface WindowSize {
    width: number | undefined;
    height: number | undefined;
}

export function useWindowSize(): [number | undefined, number | undefined] {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
    });

    useLayoutEffect(() => {
        function handleResize(): void {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener('resize', handleResize, {
            passive: true,
        });

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return [windowSize.width, windowSize.height];
}
