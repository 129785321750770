import { pushElementInteraction } from '@frontend/analytics';
import { Box, FontAwesomeIcon, IconsSolid, Typography } from '@lendoab/aphrodite';
import { Badge } from '@lendoab/aphrodite-chat';
import { classNames } from 'APP/helpers/css';
import React from 'react';

import styles from './FirstLevelNavigationLink.module.scss';

interface Props {
    text: string;
    onChevronClick?: (isExpanded: boolean) => void;
    isActive?: boolean;
    isOpen?: boolean;
    unreadMessages?: number;
}

export const FirstLevelNavigationLink = ({
    text,
    onChevronClick,
    isActive = false,
    isOpen = false,
    unreadMessages,
}: Props): React.JSX.Element => {
    function handleChevronClick(): void {
        onChevronClick?.(!isOpen);

        if (!isOpen) {
            const hasUnreadMessageProp = typeof unreadMessages !== 'undefined';

            pushElementInteraction({
                id: text,
                group: 'navbar',
                type: 'menu open',
                context: 'my pages',
                ...(hasUnreadMessageProp && { activeNotification: unreadMessages > 0 }),
            });
        }
    }

    return (
        <Box
            backgroundColor="transparent"
            component="button"
            display="flex"
            alignItems="center"
            paddingY="medium"
            paddingX={['xl', 'xl', 'medium']}
            cursor="pointer"
            className={classNames(styles.firstLevelNavigationLink, isActive && styles.active, isOpen && styles.isOpen)}
            data-testid="first-level-navigation-link"
            aria-current={isActive}
            onClick={handleChevronClick}
        >
            {unreadMessages ? (
                <Box display="flex" alignItems="center" gap="xs">
                    <Typography.Body weight="medium">{text}</Typography.Body>
                    {unreadMessages > 0 && <Badge count={unreadMessages} size={20} data-testid="unread-messages" />}
                </Box>
            ) : (
                <Typography.Body weight="medium">{text}</Typography.Body>
            )}
            <FontAwesomeIcon icon={IconsSolid.faChevronDown} color="gray-10" size="small" />
        </Box>
    );
};
