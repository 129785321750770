import { Box } from '@lendoab/aphrodite';
import { classNames } from 'APP/helpers/css';
import React, { ReactElement } from 'react';

import styles from './HamburgerToggle.module.scss';

export function HamburgerToggle(props: { open: boolean; onClick: () => void; [key: string]: unknown }): ReactElement {
    const { open, ...rest } = props;
    const hamburgerClassName = classNames(styles.hamburger, open && styles.open);

    return (
        <Box {...rest} className={styles.hamburgerButton} data-testid="hamburger-menu-toggle">
            <div className={hamburgerClassName}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </Box>
    );
}
